import AppConfig from 'AppConfig';
import { User } from 'types';
import http from 'api/http';
import { handleResponse } from 'api/helpers';

export async function getUsers(orgId: string): Promise<User[]> {
  const response = await http.get(`${AppConfig.authyApiUrl}/users?org_id=${orgId}`);

  return await handleResponse(response, (json) => json.data as User[]);
}

import React from 'react';
import { LoadingState } from 'types';
import { useDispatch, useTypedSelector } from 'store';
import { fetchAssetTypes } from 'store/slices/assetTypes/assetTypes';
import { fetchRecordTypes } from 'store/slices/recordTypes/recordTypes';
import { useUsersQuery } from 'hooks/queries/users/useUsersQuery';
import { useGroupsQuery } from 'hooks/queries/groups/useGroupsQuery';
import { AssetTypeCategoriesContext, useAssetTypeCategoryRouting } from 'hooks/useAssetTypeCategories';

interface AssetTypesLoaderProps {
  orgId: string;
  children?: React.ReactNode;
}

function isLoadingState(loadingState: LoadingState) {
  return loadingState === LoadingState.idle || loadingState === LoadingState.loading;
}

/**
 * Loads all the initial data used throughout the inventory application.
 * This is the list of all asset types, users, and groups.
 */
export default function InitialDataLoader({ orgId, children }: AssetTypesLoaderProps): JSX.Element | null {
  const dispatch = useDispatch();
  const { isLoaded: isCategoriesLoaded, appName, assetTypeCategories } = useAssetTypeCategoryRouting();

  const usersQuery = useUsersQuery(); // warm up users cache
  const groupsQuery = useGroupsQuery(); // warm up groups cache

  const { allLoadingState: loadAssetTypesState, allLoadingError: assetTypesError } = useTypedSelector(
    (state) => state.assetTypes,
  );
  const { allLoadingState: loadRecordTypesState, allLoadingError: recordTypesError } = useTypedSelector(
    (state) => state.recordTypes,
  );

  const error = assetTypesError || recordTypesError || usersQuery.isLoadingError || groupsQuery.isLoadingError;

  const isLoading =
    isLoadingState(loadAssetTypesState) ||
    isLoadingState(loadRecordTypesState) ||
    usersQuery.isLoading ||
    groupsQuery.isLoading ||
    !isCategoriesLoaded;

  React.useEffect(() => {
    if (isCategoriesLoaded) {
      dispatch(fetchAssetTypes({ assetTypeCategories }));
      dispatch(fetchRecordTypes());
    }
  }, [dispatch, orgId, isCategoriesLoaded, assetTypeCategories]);

  if (error) {
    throw error;
  }

  if (isLoading) {
    return null;
  }

  return (
    <AssetTypeCategoriesContext.Provider value={{ appName, assetTypeCategories }}>
      {children}
    </AssetTypeCategoriesContext.Provider>
  );
}

// Do not edit this file, it is automatically generated
const locales = {
  "zh": {
    "translation": {
      "app_name": {
        "asset_inventory": "资产库存",
        "risk_register": "风险登记册",
        "default": "对象清单"
      },
      "activities_page": {
        "activities": "活动",
        "generate_activity_log": "生成活动日志",
        "bubbly_message": "选择最多 {{num_days}} 天的时间范围以生成一份日志，其中包含贵组织在资产库存和风险登记册中的活动。我们将向您发送一封电子邮件，其中包含可用来下载活动日志的链接。\n",
        "bubbly_warning": "该下载链接将在您收到电子邮件 30 分钟后过期。",
        "success_toast_message": "您的导出已开始。一旦活动日志文件准备就绪，我们将向您发送一封包含指向该文件的链接的电子邮件。\n请注意，如果您的文件包含大量活动，则导出过程可能需要花费一段时间。\n",
        "export_activity_log": "导出活动日志",
        "error_date_range_too_long": "您一次可导出最多 {{num_days}} 天的活动。请选择更短的时间范围。"
      },
      "activity_log": "活动",
      "object_types": {
        "asset": "资产",
        "record": "记录"
      },
      "breadcrumbs": "面包屑",
      "links": {
        "support_url": "https://community.wegalvanize.com/s/contactsupport?language=zh_cn",
        "empty_assessments_help_url": "https://help.highbond.com/helpdocs/highbond/zh-cn/Default.htm#cshid=pm-asset-workflows"
      },
      "no_results": {
        "heading": "未找到结果",
        "body": "请缩小搜索范围或选择不同的过滤器。"
      },
      "empty_results": {
        "heading": "这里什么都没有！",
        "body": "添加{{- recordTypeName}}以为此{{- parentName}}创建新记录。"
      },
      "error_notification": {
        "heading": "出问题了",
        "body": "出了些问题。请刷新页面再试一次。如果问题仍然存在，请<a href=\"{{support_url}}\" rel=\"noopener noreferrer\" target=\"_blank\">联系我们的支持团队</a>。"
      },
      "no_access_notification": {
        "heading": "禁止访问",
        "body": "您没有权限访问此数据。试着联系贵组织中的 HighBond 管理员，看看您是否应该有访问权限。"
      },
      "asset_types_page": {
        "page_title": {
          "asset_inventory": "资产库存 - HighBond",
          "risk_register": "风险登记册 - HighBond",
          "default": "对象清单 - HighBond"
        },
        "heading": {
          "asset_inventory": "资产库存",
          "risk_register": "风险登记册",
          "default": "对象清单"
        },
        "asset_type": {
          "asset_inventory": "资产类型",
          "default": "类型"
        },
        "description": "描述"
      },
      "assets_index_page": {
        "page_title": {
          "asset_inventory": "{{- assetTypeName}} - 资产库存",
          "risk_register": "{{- assetTypeName}} - 风险登记册",
          "default": "{{- assetTypeName}} - 对象清单"
        },
        "not_configured": {
          "heading": "未配置{{- appTitle}}",
          "message": "您的组织中尚未配置{{- appTitle}}。请与您的系统管理员联系。"
        }
      },
      "details_page": {
        "heading": "{{- assetTypeName}} 详细信息",
        "page_title": {
          "asset_inventory": "{{- assetName}} - 资产库存",
          "risk_register": "{{- assetName}} - 风险登记册",
          "default": "{{- assetName}} - 对象清单"
        },
        "error": "出了些问题。请刷新页面再试一次。如果问题仍然存在，请<a href=\"{{support_url}}\" rel=\"noopener noreferrer\" target=\"_blank\">联系我们的支持团队</a>。",
        "workflow_status": {
          "status_transition_errors": "保存{{- name}}时出现问题：",
          "status_transition_errors_plural": "保存{{- name}}时出现些问题：",
          "updated_by": "操作者：{{userName}}"
        },
        "add_record": "添加{{- recordTypeName}}",
        "overview": "概览",
        "details": "详情",
        "loading": "正在加载…",
        "number_of_attributes": "{{attributesCount}} 个属性",
        "number_of_required_attributes": "{{attributesCount}} 个必须属性",
        "questionnaires": {
          "no_results": {
            "heading": "寻找评估？",
            "body": "可以使用屏幕顶部的工作流控制来启动评估。<a href='{{supportPage}}' rel=\"noopener noreferrer\" target=\"_blank\">了解详细信息</a>"
          },
          "side_panel": {
            "header": "发送调查问卷",
            "select_questionnaire_label": "调查问卷",
            "select_questionnaire_placeholder": "选择调查问卷…",
            "select_email_label": "收件人",
            "contact_instructions": "输入自定义电子邮件或选择现有用户以接收调查问卷",
            "invalid_email": "请输入一个有效的电子邮件地址。",
            "asset_type_validation": {
              "asset_inventory": "尚未为评估调查问卷设置此资产。请与管理员联系，将评估调查问卷链接到此资产类型。",
              "default": "尚未为评估调查问卷设置此对象。请与管理员联系，以便将评估调查问卷链接到此类型。"
            },
            "contact_custom_text": "使用自定义电子邮件地址",
            "contact_custom_instructions": "搜索用户或输入自定义电子邮件地址",
            "search_placeholder": "搜索…"
          }
        },
        "section_header": {
          "additional_information": "附加信息",
          "number_of_errors": {
            "singular": "{{numberOfErrors}} 个错误",
            "plural": "{{numberOfErrors}} 个错误"
          },
          "required_attributes_missing": "有 {{numberOfPresentAttributes}} 个，需要 {{numberOfRequiredAttributes}} 个"
        }
      },
      "actions": {
        "actions": "操作",
        "send": "发送",
        "apply": "应用",
        "close": "关闭",
        "save": "保存",
        "cancel": "取消",
        "decline": "拒绝",
        "approve": "批准",
        "save_changes": "保存更改",
        "discard_changes": "放弃更改",
        "add_asset": "添加{{- assetTypeName}}",
        "delete": "删除",
        "delete_asset": {
          "label": "删除",
          "heading": "删除{{- assetName}}？",
          "confirmation": {
            "asset_inventory": "这将永久删除{{- assetName}}和任何相关工作，包括资产摘要、评估和相关记录。您将无法访问或恢复其中任何项目。",
            "default": "这将永久删除{{- assetName}}和任何相关工作，包括其摘要、评估和相关记录。您将无法访问或恢复其中任何项目。"
          }
        },
        "delete_record": {
          "label": "删除",
          "heading": "删除{{- recordName}}？",
          "confirmation": "确定要删除{{- recordName}}？这将删除任何相关工作，包括其相关记录。您将无法访问或恢复其中任何项目。"
        },
        "download_report": "下载报告",
        "download": "下载",
        "sort": {
          "ascending": "升序排序",
          "descending": "降序排序"
        },
        "data_header_menu_trigger": {
          "hide_column": "隐藏列",
          "direction": {
            "ascending": "升序",
            "descending": "降序"
          },
          "with_direction": "列{{- columnName}}，{{- sortDirection}}排序，更多操作。",
          "without_direction": "列{{- columnName}}，更多操作。",
          "sort_column_ascending": "升序排序 {{- columnName}}。",
          "sort_column_descending": "降序排序 {{- columnName}}。"
        },
        "done": "完成"
      },
      "data_types": {
        "boolean": {
          "true": "真",
          "false": "假"
        },
        "datetime": {
          "date": "日期",
          "time": "时间"
        },
        "ad_hoc_relationship": {
          "cancel": "取消",
          "connection_type": "连接类型",
          "link": "链接",
          "unlink": "取消链接",
          "search_display_name": "过滤 {{displayName}}",
          "add": "添加 {{label}}",
          "target_object_display_title": "ID {{- customerObjectId}} - {{- customerObjectName}}",
          "search_issue": "搜索…",
          "no_results": "未找到结果"
        },
        "attachment": {
          "add": "添加{{attributeTypeName}}",
          "remove": "是否移除附件{{fileName}}？",
          "general_error": "无法加载附件。请刷新页面再试一次。如果问题仍然存在，请<a href=\"{{support_url}}\" rel=\"noopener noreferrer\" target=\"_blank\">联系我们的支持团队</a>。",
          "no_access_notification": "您无权访问这些附件。试着联系贵组织中的 HighBond 管理员，看看您是否应该有访问权限。",
          "asset_grid_label": "文件",
          "grid": {
            "id": "名称",
            "last_modified": "已上传",
            "modified_by": "上传者：",
            "actions": "操作",
            "download_file": "是否下载{{fileName}}？",
            "file_infected": "无法下载{{fileName}}，因为它已被感染。",
            "file_pending": "无法下载{{fileName}}，因为它正在被扫描以确认它 是否为恶意软件。",
            "preparing_download": "正在准备下载",
            "error_getting_download_url": "无法下载该文件。请稍后重试，或者与您的系统管理员联系以解决此问题。"
          },
          "loading": "正在加载…",
          "add_modal": {
            "heading": "添加新{{attributeTypeName}}",
            "tooltip_content": "出于安全原因，HighBond 不接受具有以下扩展名的文件附件：.bat、.com 或 .exe。\n",
            "label": "上传新{{attributeTypeName}}",
            "error_message_for_reaching_maximum_number": "达到最大文件数量。请删除现有文件，然后重试。",
            "error_message_for_reaching_1": "只能为此属性类型添加一个文件。请删除现有文件，然后重试。",
            "error_message_for_invalid_extension": "不允许使用此附件的文件扩展名。请尝试其他文件扩展名。",
            "error_message_for_duplicated_filename": "此文件已作为附件上传到属性。",
            "error_message_for_general_error": "未能添加附件。请稍后重试，或者与您的系统管理员联系以解决此问题。"
          }
        }
      },
      "global_notification": {
        "customer_object_created": {
          "success": "{{- customerObjectTypeName}} \"{{- customerObjectName}}\" 已成功添加。"
        },
        "asset_updated": {
          "success": "您已成功更新一个{{- assetTypeName}}！",
          "conflict": {
            "title": "保存冲突",
            "description": "您的更改与另一个用户同时进行的更改冲突。\n要保存更改，请复制数据，刷新页面，然后重新提交更改\n"
          }
        },
        "asset_deleted": {
          "success": "{{- assetTypeName}} \"{{- assetName}}\" 已成功删除。",
          "error": "\"{{- assetName}}\" 删除失败。"
        },
        "go_to": "转至 \"{{- assetName}}\"",
        "record_updated": {
          "success": "已成功更新记录！"
        },
        "questionnaire_sent": {
          "success": "调查问卷已发出。"
        }
      },
      "side_navigation": {
        "objects_heading": {
          "asset_inventory": "资产",
          "default": "对象"
        },
        "records_heading": "记录"
      },
      "error_page": {
        "page_title": {
          "asset_inventory": "{{heading}} - 资产库存",
          "risk_register": "{{heading}} - 风险登记册",
          "default": "{{heading}} - 对象清单"
        },
        "page_not_found": {
          "heading": "未找到页面",
          "body": "找不到您所查找的页面。您可以返回我们的<a href='{{systemHomepage}}' rel=\"noopener noreferrer\" target=\"_blank\">系统主页</a>，或<a href='{{contactSupportTeam}}' rel=\"noopener noreferrer\" target=\"_blank\">联系我们的支持团队</a>获取进一步的帮助。"
        },
        "access_not_allowed": {
          "heading": "不允许访问",
          "body": "根据我们的记录，您没有访问此页面的权限。试着联系贵组织中的 HighBond 管理员，看看您是否应该有访问权限。或者您可以返回我们的<a href='{{systemHomepage}}' rel=\"noopener noreferrer\" target=\"_blank\">系统主页</a>，或<a href='{{contactSupportTeam}}' rel=\"noopener noreferrer\" target=\"_blank\">联系我们的支持团队</a>获取进一步的帮助。"
        },
        "footer": "如需进一步的帮助，<a href='{{visitOurSupportPage}}' rel=\"noopener noreferrer\" target=\"_blank\">请访问我们的支持页面</a>，记录支持请求单或与客服代表在线聊天。"
      },
      "add_asset_modal": {
        "unsaved_changes_warning": "您已做出更改，关闭此表单将使这些更改丢失。",
        "add_new_item": "添加",
        "name_is_required": "名称是必需的。",
        "item_type": "项目类型",
        "name": "名称"
      },
      "dirty_form": {
        "title": "此{{- objectTypeName}}有未保存的更改。",
        "missing_name_error": "“名称”为空时无法保存此{{- objectTypeName}}。",
        "description": "您看起来正在进行更新操作。如果继续，您将丢失所有未保存的更改。",
        "save_and_continue": "保存并继续",
        "discard_and_continue": "不保存继续",
        "cancel": "取消"
      },
      "impact_reports_modal": {
        "download_report": {
          "title": "下载报告",
          "can_be_downloaded": "可按下列格式下载报告。",
          "ready_to_download": "您的报告已准备好下载。"
        },
        "just_a_moment": {
          "title": "稍等！",
          "description": "我们正在准备您的报告。这可能要几分钟，取决于报告的大小。报告准备好后下载按钮即变为可用。"
        },
        "format_selection": {
          "label": "报告格式",
          "instructions": "选择报告格式"
        },
        "docx_format": "Microsoft Word",
        "xlsx_format": "Microsoft Excel",
        "pptx_format": "Microsoft PowerPoint",
        "pdf_format": "PDF",
        "no_reports": {
          "title": "未找到报告。",
          "description": "当前没有可供下载的报告。"
        }
      },
      "errors": {
        "handlers": {
          "required_fields_missing": "需要{{attributeNameComponent}}。",
          "required_relationships_missing": {
            "asset_inventory": "{{attributeNameComponent}}必须链接到至少一个资产或资产记录。",
            "default": "必须将{{attributeNameComponent}}链接至至少一个对象。"
          },
          "status_not_found": "目标状态 ({{statusId}}) 不在当前状态的工作流中。",
          "questionnaire_not_found": "在处理程序中找不到调查问卷。",
          "failed_to_update_status": "无法更新工作流状态。请刷新页面再试一次。如果问题仍然存在，请<a href=\"{{support_url}}\" rel=\"noopener noreferrer\" target=\"_blank\">联系我们的支持团队</a>。",
          "failed_to_send_questionnaire": "无法发送评估调查问卷。请刷新页面再试一次。如果问题仍然存在，请<a href=\"{{support_url}}\" rel=\"noopener noreferrer\" target=\"_blank\">联系我们的支持团队</a>。",
          "failed_to_publish_protobuf": "无法执行 “notify” 或 “runRobot” 操作。请刷新页面再试一次。如果问题仍然存在，请<a href=\"{{support_url}}\" rel=\"noopener noreferrer\" target=\"_blank\">联系我们的支持团队</a>。",
          "no_access_notification": "您没有执行此操作的权限。请尝试联系贵组织中的 HighBond 管理员，了解您是否应该拥有权限。"
        },
        "form": {
          "missing_field_value": "字段不能为空。",
          "missing_relationship_value": {
            "asset_inventory": "字段必须链接到至少一个资产或资产记录。",
            "default": "必须将字段链接到至少一个对象。"
          },
          "invalid_field_value": "字段有无效值。"
        },
        "configuration": {
          "section_missing_attribute_error": "需要先填写一些对您不可见的属性，然后才能继续。 请与管理员联系以查看此{{- customerObjectTypeName}}的配置。\n"
        }
      },
      "metadata": {
        "record_id": "记录 ID",
        "position": "位置",
        "priority": "优先级",
        "status": "状态",
        "publish_date": "发布日期",
        "publisher": "发布者",
        "assignee": "被分配人",
        "group": "组",
        "updated_at": "更新于",
        "closed_at": "关闭于",
        "object_type": "对象类型",
        "object_id": "对象 ID",
        "object_container_type": "对象容器类型",
        "object_container_id": "对象容器 ID"
      },
      "protected": {
        "source": "源",
        "user_id": "用户 ID",
        "modified_by": "修改者",
        "status_id": "状态 ID",
        "priority_id": "优先级 ID",
        "group_id": "组 ID",
        "external_source": "外部源",
        "external_type": "外部类型",
        "external_id": "外部 ID",
        "questionnaire_context": "调查问卷上下文"
      }
    }
  }
};
export default locales;
// Do not edit this file, it is automatically generated
const locales = {
  "en": {
    "translation": {
      "app_name": {
        "asset_inventory": "Asset Inventory",
        "risk_register": "Risk Register",
        "default": "Object Inventory"
      },
      "activities_page": {
        "activities": "Activities",
        "generate_activity_log": "Generate activity log",
        "bubbly_message": "Generate a log containing your organization's activity in Asset Inventory and Risk Register by selecting a date range of up to {{num_days}} days. We'll send you an email with a link where you can download the activity log.\n",
        "bubbly_warning": "The download link will expire 30 minutes after you receive the email.",
        "success_toast_message": "Your export has started. We will send you an email with a link to your activity log file once it's ready.\nPlease note that the export process can take a while if your file contains a large number of activities.\n",
        "export_activity_log": "Export activity log",
        "error_date_range_too_long": "You can export up to {{num_days}} days of activities at a time. Please select a smaller date range."
      },
      "activity_log": "Activity",
      "object_types": {
        "asset": "Asset",
        "record": "Record"
      },
      "breadcrumbs": "Breadcrumbs",
      "links": {
        "support_url": "https://community.wegalvanize.com/s/contactsupport",
        "empty_assessments_help_url": "https://help.highbond.com/helpdocs/highbond/en-us/Default.htm#cshid=pm-asset-workflows"
      },
      "no_results": {
        "heading": "No Results Found",
        "body": "Please refine your search or choose a different filter."
      },
      "empty_results": {
        "heading": "Nothing Here Yet!",
        "body": "Add {{- recordTypeName}} to create a new record for this {{- parentName}}."
      },
      "error_notification": {
        "heading": "A Problem Occurred",
        "body": "Something went wrong. Please refresh the page to try again. If the problem persists, <a href=\"{{support_url}}\" rel=\"noopener noreferrer\" target=\"_blank\">contact our support team</a>."
      },
      "no_access_notification": {
        "heading": "No Access",
        "body": "You don't have access to this data. Try reaching out to a HighBond admin in your organization to see if you should have access."
      },
      "asset_types_page": {
        "page_title": {
          "asset_inventory": "Asset Inventory - HighBond",
          "risk_register": "Risk Register - HighBond",
          "default": "Object Inventory - Highbond"
        },
        "heading": {
          "asset_inventory": "Asset Inventory",
          "risk_register": "Risk Register",
          "default": "Object Inventory"
        },
        "asset_type": {
          "asset_inventory": "Asset type",
          "default": "Type"
        },
        "description": "Description"
      },
      "assets_index_page": {
        "page_title": {
          "asset_inventory": "{{- assetTypeName}} - Asset Inventory",
          "risk_register": "{{- assetTypeName}} - Risk Register",
          "default": "{{- assetTypeName}} - Object Inventory"
        },
        "not_configured": {
          "heading": "{{- appTitle}} not configured",
          "message": "{{- appTitle}} is not yet configured in your org. Please contact your system administrator."
        }
      },
      "details_page": {
        "heading": "{{- assetTypeName}} details",
        "page_title": {
          "asset_inventory": "{{- assetName}} - Asset Inventory",
          "risk_register": "{{- assetName}} - Risk Register",
          "default": "{{- assetName}} - Object Inventory"
        },
        "error": "Something went wrong. Please refresh the page to try again. If the problem persists, <a href=\"{{support_url}}\" rel=\"noopener noreferrer\" target=\"_blank\">contact our support team</a>.",
        "workflow_status": {
          "status_transition_errors": "A problem occurred while saving {{- name}}:",
          "status_transition_errors_plural": "Some problems occurred while saving {{- name}}:",
          "updated_by": "by {{userName}}"
        },
        "add_record": "Add {{- recordTypeName}}",
        "overview": "Overview",
        "details": "Details",
        "loading": "Loading...",
        "number_of_attributes": "{{attributesCount}} attributes",
        "number_of_required_attributes": "{{attributesCount}} required attributes",
        "questionnaires": {
          "no_results": {
            "heading": "Looking for Assessments?",
            "body": "You can start an assessment by using the workflow controls at the top of the screen. <a href='{{supportPage}}' rel=\"noopener noreferrer\" target=\"_blank\">Learn more</a>"
          },
          "side_panel": {
            "header": "Send questionnaire",
            "select_questionnaire_label": "Questionnaire",
            "select_questionnaire_placeholder": "Select a questionnaire...",
            "select_email_label": "Recipient",
            "contact_instructions": "Enter a custom email or select an existing user to receive the questionnaire",
            "invalid_email": "Please enter a valid email address.",
            "asset_type_validation": {
              "asset_inventory": "This asset has not been set up for assessment questionnaires. Contact an administrator to link assessment questionnaires to this asset type.",
              "default": "This object has not been set up for assessment questionnaires. Contact an administrator to link assessment questionnaires to this type."
            },
            "contact_custom_text": "Use as custom email address",
            "contact_custom_instructions": "Search for a user or enter a custom email address",
            "search_placeholder": "Search..."
          }
        },
        "section_header": {
          "additional_information": "Additional information",
          "number_of_errors": {
            "singular": "{{numberOfErrors}} error",
            "plural": "{{numberOfErrors}} errors"
          },
          "required_attributes_missing": "{{numberOfPresentAttributes}} of {{numberOfRequiredAttributes}} required"
        }
      },
      "actions": {
        "actions": "Actions",
        "send": "Send",
        "apply": "Apply",
        "close": "Close",
        "save": "Save",
        "cancel": "Cancel",
        "decline": "Decline",
        "approve": "Approve",
        "save_changes": "Save changes",
        "discard_changes": "Discard changes",
        "add_asset": "Add {{- assetTypeName}}",
        "delete": "Delete",
        "delete_asset": {
          "label": "Delete",
          "heading": "Delete {{- assetName}}?",
          "confirmation": {
            "asset_inventory": "This will permanently delete {{- assetName}} and any associated work, including asset profile, assessments and related records. You will not be able to access or restore any of these items.",
            "default": "This will permanently delete {{- assetName}} and any associated work, including its profile, assessments and related records. You will not be able to access or restore any of these items."
          }
        },
        "delete_record": {
          "label": "Delete",
          "heading": "Delete {{- recordName}}?",
          "confirmation": "Are you sure you want to delete {{- recordName}}?  This will delete any associated work, including its related records. You will not be able to access or restore any of these items."
        },
        "download_report": "Download Report",
        "download": "Download",
        "sort": {
          "ascending": "Sort ascending",
          "descending": "Sort descending"
        },
        "data_header_menu_trigger": {
          "hide_column": "Hide column",
          "direction": {
            "ascending": "ascending",
            "descending": "descending"
          },
          "with_direction": "Column {{- columnName}}, sorted {{- sortDirection}}, more actions.",
          "without_direction": "Column {{- columnName}}, more actions.",
          "sort_column_ascending": "Sort {{- columnName}} ascending.",
          "sort_column_descending": "Sort {{- columnName}} descending."
        },
        "done": "Done"
      },
      "data_types": {
        "boolean": {
          "true": "True",
          "false": "False"
        },
        "datetime": {
          "date": "Date",
          "time": "Time"
        },
        "ad_hoc_relationship": {
          "cancel": "Cancel",
          "connection_type": "Connection type",
          "link": "Link",
          "unlink": "Unlink",
          "search_display_name": "Filter {{displayName}}",
          "add": "Add {{label}}",
          "target_object_display_title": "ID {{- customerObjectId}} - {{- customerObjectName}}",
          "search_issue": "Search...",
          "no_results": "No results found"
        },
        "attachment": {
          "add": "Add {{attributeTypeName}}",
          "remove": "Remove attachment {{fileName}}?",
          "general_error": "Unable to load attachments. Please refresh the page to try again. If the problem persists, <a href=\"{{support_url}}\" rel=\"noopener noreferrer\" target=\"_blank\">contact our support team</a>.",
          "no_access_notification": "You don't have access to these attachments. Try reaching out to a HighBond admin in your organization to see if you should have access.",
          "asset_grid_label": "Files",
          "grid": {
            "id": "Name",
            "last_modified": "Uploaded",
            "modified_by": "Uploaded by",
            "actions": "Actions",
            "download_file": "Download {{fileName}}?",
            "file_infected": "File {{fileName}} cannot be downloaded because it is infected.",
            "file_pending": "File {{fileName}} cannot be downloaded because it is being scanned for malware.",
            "preparing_download": "Preparing download",
            "error_getting_download_url": "This file cannot be downloaded. Please try again later or contact your System Administrator to resolve this issue."
          },
          "loading": "Loading...",
          "add_modal": {
            "heading": "Add new {{attributeTypeName}}",
            "tooltip_content": "For security reasons, HighBond does not accept file attachments with the following extensions: .bat, .com, or .exe.\n",
            "label": "Upload new {{attributeTypeName}}",
            "error_message_for_reaching_maximum_number": "Maximum number of files reached. Please delete the existing file and try again.",
            "error_message_for_reaching_1": "Only one file can be added for this attribute type. Please delete the existing file and try again.",
            "error_message_for_invalid_extension": "File extension for this attachment is not allowed. Please try a different file extension.",
            "error_message_for_duplicated_filename": "This file has already been uploaded as attachment to the attribute.",
            "error_message_for_general_error": "Failed to add attachment. Please try again later or contact your System Administrator to resolve this issue."
          }
        }
      },
      "global_notification": {
        "customer_object_created": {
          "success": "{{- customerObjectTypeName}} \"{{- customerObjectName}}\" has been successfully added."
        },
        "asset_updated": {
          "success": "You have successfully updated a {{- assetTypeName}}!",
          "conflict": {
            "title": "Save conflict",
            "description": "Your changes conflict with those made concurrently by another user.\nTo save your changes, copy your data, refresh the page, and resubmit your changes\n"
          }
        },
        "asset_deleted": {
          "success": "{{- assetTypeName}} \"{{- assetName}}\" has been successfully deleted.",
          "error": "\"{{- assetName}}\" failed to delete."
        },
        "go_to": "Go To \"{{- assetName}}\"",
        "record_updated": {
          "success": "You have successfully updated a record!"
        },
        "questionnaire_sent": {
          "success": "Questionnaire has been sent."
        }
      },
      "side_navigation": {
        "objects_heading": {
          "asset_inventory": "Assets",
          "default": "Objects"
        },
        "records_heading": "Records"
      },
      "error_page": {
        "page_title": {
          "asset_inventory": "{{heading}} - Asset Inventory",
          "risk_register": "{{heading}} - Risk Register",
          "default": "{{heading}} - Object Inventory"
        },
        "page_not_found": {
          "heading": "Page not found",
          "body": "The page you were looking for couldn’t be found. You can go back to our <a href='{{systemHomepage}}' rel=\"noopener noreferrer\" target=\"_blank\">system home page</a>, or <a href='{{contactSupportTeam}}' rel=\"noopener noreferrer\" target=\"_blank\">contact our support team</a> for further assistance."
        },
        "access_not_allowed": {
          "heading": "Access is not allowed",
          "body": "According to our records you do not have permission to access this page. Try reaching out to a HighBond admin in your organization to see if you should have access. Alternatively you can go back to the <a href='{{systemHomepage}}' rel=\"noopener noreferrer\" target=\"_blank\">system home page</a>, or <a href='{{contactSupportTeam}}' rel=\"noopener noreferrer\" target=\"_blank\">contact our support team</a> for further assistance."
        },
        "footer": "For further assistance, <a href='{{visitOurSupportPage}}' rel=\"noopener noreferrer\" target=\"_blank\">visit our support page</a>, to log a ticket or chat online with a representative."
      },
      "add_asset_modal": {
        "unsaved_changes_warning": "You have made changes that will be lost if you close this form.",
        "add_new_item": "Add",
        "name_is_required": "Name is required.",
        "item_type": "Item type",
        "name": "Name"
      },
      "dirty_form": {
        "title": "This {{- objectTypeName}} has unsaved changes.",
        "missing_name_error": "This {{- objectTypeName}} cannot be saved while \"name\" is blank.",
        "description": "It looks like you were in the middle of making updates. If you continue you will lose all unsaved changes.",
        "save_and_continue": "Save and continue",
        "discard_and_continue": "Continue without saving",
        "cancel": "Cancel"
      },
      "impact_reports_modal": {
        "download_report": {
          "title": "Download report",
          "can_be_downloaded": "Reports can be downloaded in the following formats.",
          "ready_to_download": "Your report is ready to download."
        },
        "just_a_moment": {
          "title": "Just a moment!",
          "description": "We're preparing your report. This can take several minutes depending on the size of the report. The download button will become available once the report is ready."
        },
        "format_selection": {
          "label": "Report formats",
          "instructions": "Select a report format"
        },
        "docx_format": "Microsoft Word",
        "xlsx_format": "Microsoft Excel",
        "pptx_format": "Microsoft PowerPoint",
        "pdf_format": "PDF",
        "no_reports": {
          "title": "No Reports Found.",
          "description": "There are currently no reports available for download."
        }
      },
      "errors": {
        "handlers": {
          "required_fields_missing": "{{attributeNameComponent}} is required.",
          "required_relationships_missing": {
            "asset_inventory": "{{attributeNameComponent}} must be linked to at least one asset or asset record.",
            "default": "{{attributeNameComponent}} must be linked to at least one object."
          },
          "status_not_found": "Destination status ({{statusId}}) is not in the workflow of the current status.",
          "questionnaire_not_found": "Questionnaire is not found in Handlers.",
          "failed_to_update_status": "Unable to update workflow status. Please refresh the page to try again. If the problem persists, <a href=\"{{support_url}}\" rel=\"noopener noreferrer\" target=\"_blank\">contact our support team</a>.",
          "failed_to_send_questionnaire": "Unable to send assessment questionnaire. Please refresh the page to try again. If the problem persists, <a href=\"{{support_url}}\" rel=\"noopener noreferrer\" target=\"_blank\">contact our support team</a>.",
          "failed_to_publish_protobuf": "Unable to execute \"notify\" or \"runRobot\" action. Please refresh the page to try again. If the problem persists, <a href=\"{{support_url}}\" rel=\"noopener noreferrer\" target=\"_blank\">contact our support team</a>.",
          "no_access_notification": "You do not have permission to perform this action. Try reaching out to a HighBond admin in your organization to see if you should have permission."
        },
        "form": {
          "missing_field_value": "Field cannot be blank.",
          "missing_relationship_value": {
            "asset_inventory": "Field must be linked to at least one asset or asset record.",
            "default": "Field must be linked to at least one object."
          },
          "invalid_field_value": "Field has an invalid value."
        },
        "configuration": {
          "section_missing_attribute_error": "Some attributes that are not visible to you need to be filled in before you can continue. Please contact your administrator to review the configuration of this {{- customerObjectTypeName}}.\n"
        }
      },
      "metadata": {
        "record_id": "Record ID",
        "position": "Position",
        "priority": "Priority",
        "status": "Status",
        "publish_date": "Publish Date",
        "publisher": "Publisher",
        "assignee": "Assignee",
        "group": "Group",
        "updated_at": "Updated At",
        "closed_at": "Closed At",
        "object_type": "Object Type",
        "object_id": "Object ID",
        "object_container_type": "Object Container Type",
        "object_container_id": "Object Container ID"
      },
      "protected": {
        "source": "Source",
        "user_id": "User ID",
        "modified_by": "Modified By",
        "status_id": "Status ID",
        "priority_id": "Priority ID",
        "group_id": "Group ID",
        "external_source": "External Source",
        "external_type": "External Type",
        "external_id": "External ID",
        "questionnaire_context": "Questionnaire Context"
      }
    }
  }
};
export default locales;
import React, { Suspense, lazy } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/de';
import 'moment/locale/es';
import 'moment/locale/fr';
import 'moment/locale/ja';
import 'moment/locale/pt';
import 'moment/locale/zh-cn';
import L10n from '@paprika/l10n';
import useI18n from '@paprika/l10n/lib/useI18n';
import Guard from '@paprika/guard';
import Locales from '../../locales';
import GlobalNav from '../GlobalNav';
import store from 'store';
import { useSession } from 'providers/SessionProvider';
import GlobalNotificationToast from 'components/common/GlobalNotificationToast';
import InitialDataLoader from 'components/common/InitialDataLoader';
import SideNav from 'components/common/SideNav';
import RouteWithFallback from 'components/errors/RouteWithFallback';
import ErrorBoundary from 'components/errors/ErrorBoundary';
import Global404Page from 'components/errors/Global404Page';
import RedirectAs404 from 'components/errors/RedirectAs404';
import Spinner from '@paprika/spinner';
import { Flippers } from 'AppConfig';

import './App.scss';

const AssetTypesPage = lazy(() => import('components/assets/AssetTypes/AssetTypes'));
const AssetTypePage = lazy(() => import('components/assets/AssetTypeRoute'));
const RecordTypePage = lazy(() => import('components/records/RecordTypeRoute'));
const ActivitiesPage = lazy(() => import('components/activities/Activities'));

export default function App(): JSX.Element {
  const session = useSession();
  const I18n = useI18n();
  moment.locale(session.locale === 'zh' ? 'zh-cn' : session.locale);

  return (
    <L10n locale={session.locale} locales={Locales}>
      <Guard.Supervisor alertMessageDefault={I18n.t('dirty_form.description')}>
        <Provider store={store}>
          <Router>
            <RouteWithFallback Fallback404Component={Global404Page}>
              <GlobalNav orgId={session.org_id} />
              <ErrorBoundary>
                <GlobalNotificationToast />
                <InitialDataLoader orgId={session.org_id}>
                  <main className="main-container">
                    <SideNav />
                    <div className="main-container__content">
                      <Suspense fallback={<Spinner />}>
                        <Switch>
                          <Route exact path="/" component={AssetTypesPage} />
                          <Route path="/asset_types/:assetTypeId" component={AssetTypePage} />
                          <Route path="/record_types/:recordTypeId" component={RecordTypePage} />
                          {Flippers.activities && <Route path="/activities" component={ActivitiesPage} />}
                          <RedirectAs404 />
                        </Switch>
                      </Suspense>
                    </div>
                  </main>
                </InitialDataLoader>
              </ErrorBoundary>
            </RouteWithFallback>
          </Router>
        </Provider>
      </Guard.Supervisor>
    </L10n>
  );
}

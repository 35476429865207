// Do not edit this file, it is automatically generated
const locales = {
  "ja": {
    "translation": {
      "app_name": {
        "asset_inventory": "資産インベントリ",
        "risk_register": "リスク登録簿",
        "default": "オブジェクト インベントリ"
      },
      "activities_page": {
        "activities": "活動",
        "generate_activity_log": "活動ログの生成",
        "bubbly_message": "最大 {{num_days}} 日間の日付範囲を選択して、資産インベントリおよびリスク登録の組織の活動を含むログを生成します。活動ログをダウンロードできるリンクが記載された電子メールが送信されます。\n",
        "bubbly_warning": "ダウンロード リンクは電子メールを受信してから 30 分間有効です。",
        "success_toast_message": "エクスポートが開始しました。準備が完了したら、活動ログ ファイルへのリンクが記載された電子メールが送信されます。\nファイルに含まれる活動数が多い場合は、エクスポート処理にしばらく時間がかかる可能性があります。\n",
        "export_activity_log": "活動ログをエクスポート",
        "error_date_range_too_long": "一度にエクスポートできる活動は最大 {{num_days}} 日分です。さらに狭い日付範囲を選択してください。"
      },
      "activity_log": "活動",
      "object_types": {
        "asset": "資産",
        "record": "レコード"
      },
      "breadcrumbs": "階層リンク",
      "links": {
        "support_url": "https://community.wegalvanize.com/s/contactsupport?language=ja",
        "empty_assessments_help_url": "https://help.highbond.com/helpdocs/highbond/ja/Default.htm#cshid=pm-asset-workflows"
      },
      "no_results": {
        "heading": "結果がありませんでした",
        "body": "検索を絞り込むか、異なるフィルターを選択してください。"
      },
      "empty_results": {
        "heading": "まだ何もありません!",
        "body": "{{- recordTypeName}}を追加して、この{{- parentName}}に対して新しいレコードを作成します。"
      },
      "error_notification": {
        "heading": "問題が発生しました",
        "body": "問題が発生しました。このページを更新してもう一度実行してください。問題が解決しない場合は、<a href=\"{{support_url}}\" rel=\"noopener noreferrer\" target=\"_blank\">サポート チームにお問い合わせください</a>。"
      },
      "no_access_notification": {
        "heading": "アクセス権なし",
        "body": "このデータにアクセスできません。所属組織の HighBond 管理者に連絡し、アクセス権を持つ必要があるかどうかをご確認ください。"
      },
      "asset_types_page": {
        "page_title": {
          "asset_inventory": "資産インベントリ - HighBond",
          "risk_register": "リスク登録簿 - HighBond",
          "default": "オブジェクト インベントリ - HighBond"
        },
        "heading": {
          "asset_inventory": "資産インベントリ",
          "risk_register": "リスク登録簿",
          "default": "オブジェクト インベントリ"
        },
        "asset_type": {
          "asset_inventory": "資産のタイプ",
          "default": "種類"
        },
        "description": "説明"
      },
      "assets_index_page": {
        "page_title": {
          "asset_inventory": "{{- assetTypeName}} - 資産インベントリ",
          "risk_register": "{{- assetTypeName}} - リスク登録簿",
          "default": "{{- assetTypeName}} - オブジェクト インベントリ"
        },
        "not_configured": {
          "heading": "{{- appTitle}} は構成されていません",
          "message": "{{- appTitle}} はまだ組織で構成されていません。システム管理者にお問い合わせください。"
        }
      },
      "details_page": {
        "heading": "{{- assetTypeName}}の詳細",
        "page_title": {
          "asset_inventory": "{{- assetName}} - 資産インベントリ",
          "risk_register": "{{- assetName}} - リスク登録簿",
          "default": "{{- assetName}} - オブジェクト インベントリ"
        },
        "error": "問題が発生しました。このページを更新してもう一度実行してください。問題が解決しない場合は、<a href=\"{{support_url}}\" rel=\"noopener noreferrer\" target=\"_blank\">サポート チームにお問い合わせください</a>。",
        "workflow_status": {
          "status_transition_errors": "{{- name}}を保存中に問題が発生しました:",
          "status_transition_errors_plural": "{{- name}}を保存中に何らかの問題が発生しました:",
          "updated_by": "実行者: {{userName}}"
        },
        "add_record": "{{- recordTypeName}}を追加",
        "overview": "概要",
        "details": "詳細",
        "loading": "読み込み中...",
        "number_of_attributes": "{{attributesCount}}個の属性",
        "number_of_required_attributes": "{{attributesCount}}つの必須の属性",
        "questionnaires": {
          "no_results": {
            "heading": "評価をお探しですか?",
            "body": "画面の上にあるワークフローコントロールを使用して、評価を始めることができます。<a href='{{supportPage}}' rel=\"noopener noreferrer\" target=\"_blank\">詳細表示</a>"
          },
          "side_panel": {
            "header": "アンケートの送信",
            "select_questionnaire_label": "アンケート",
            "select_questionnaire_placeholder": "アンケートを選択する...",
            "select_email_label": "受信者",
            "contact_instructions": "カスタムの電子メールを入力するか、既存のユーザーを選択してアンケートを受信します。",
            "invalid_email": "有効な電子メールアドレスを入力してください。",
            "asset_type_validation": {
              "asset_inventory": "この資産は、評価アンケート用に設定されていません。管理者に連絡して、評価アンケートをこの資産のタイプにリンク付けしてください。",
              "default": "このオブジェクトは、評価アンケート用に設定されていません。管理者に連絡して、評価アンケートをこのタイプにリンク付けしてください。"
            },
            "contact_custom_text": "カスタムの電子メールアドレスとして使用する",
            "contact_custom_instructions": "ユーザーを検索する、またはカスタムの電子メールアドレスを入力する",
            "search_placeholder": "検索..."
          }
        },
        "section_header": {
          "additional_information": "追加情報",
          "number_of_errors": {
            "singular": "{{numberOfErrors}}件のエラー",
            "plural": "{{numberOfErrors}}件のエラー"
          },
          "required_attributes_missing": "{{numberOfRequiredAttributes}}個のうち{{numberOfPresentAttributes}}個が必要です"
        }
      },
      "actions": {
        "actions": "アクション",
        "send": "送信",
        "apply": "適用",
        "close": "閉じる",
        "save": "保存",
        "cancel": "キャンセル",
        "decline": "辞退",
        "approve": "承認",
        "save_changes": "変更の保存",
        "discard_changes": "変更を破棄する",
        "add_asset": "{{- assetTypeName}}を追加",
        "delete": "削除",
        "delete_asset": {
          "label": "削除",
          "heading": "{{- assetName}}を削除しますか?",
          "confirmation": {
            "asset_inventory": "これによって、資産プロファイル、評価、関連するレコードなど、{{- assetName}}と関連付けられている作業が恒久的に削除されます。これらの項目は、アクセスすることも復元することもできなくなります。",
            "default": "これによって、プロファイル、評価、関連するレコードなど、{{- assetName}}と関連付けられている作業が恒久的に削除されます。これらの項目は、アクセスすることも復元することもできなくなります。"
          }
        },
        "delete_record": {
          "label": "削除",
          "heading": "{{- recordName}}を削除しますか?",
          "confirmation": "{{- recordName}}を削除してもよろしいですか? これによって、関連するレコードなど、関連付けられている作業が削除されます。これらの項目は、アクセスすることも復元することもできなくなります。"
        },
        "download_report": "レポートをダウンロードする",
        "download": "ダウンロード",
        "sort": {
          "ascending": "昇順の並べ替え",
          "descending": "降順の並べ替え"
        },
        "data_header_menu_trigger": {
          "hide_column": "列の非表示",
          "direction": {
            "ascending": "昇順",
            "descending": "降順"
          },
          "with_direction": "{{- columnName}}の列、{{- sortDirection}に並び替えられた、さらなるアクション。",
          "without_direction": "{{- columnName}}の列、さらなるアクション。",
          "sort_column_ascending": "{{- columnName}}を昇順に並べ替えます。",
          "sort_column_descending": "{{- columnName}}を降順に並べ替えます。"
        },
        "done": "完了"
      },
      "data_types": {
        "boolean": {
          "true": "True",
          "false": "False"
        },
        "datetime": {
          "date": "日付",
          "time": "時刻"
        },
        "ad_hoc_relationship": {
          "cancel": "キャンセル",
          "connection_type": "接続の種類",
          "link": "リンク",
          "unlink": "リンク解除",
          "search_display_name": "{{displayName}}をフィルター処理",
          "add": "{{label}}を追加",
          "target_object_display_title": "ID {{- customerObjectId}} - {{- customerObjectName}}",
          "search_issue": "検索...",
          "no_results": "結果が見つかりません"
        },
        "attachment": {
          "add": "{{attributeTypeName}}の追加",
          "remove": "添付ファイル {{fileName}} を削除しますか?",
          "general_error": "添付ファイルを読み込めません。このページを更新してもう一度実行してください。問題が解決しない場合は、<a href=\"{{support_url}}\" rel=\"noopener noreferrer\" target=\"_blank\">サポート チームにお問い合わせください</a>。",
          "no_access_notification": "これらの添付ファイルにアクセスできません。所属組織の HighBond 管理者に連絡し、アクセス権を持つ必要があるかどうかをご確認ください。",
          "asset_grid_label": "ファイル",
          "grid": {
            "id": "名前",
            "last_modified": "アップロード",
            "modified_by": "アップロード方法",
            "actions": "アクション",
            "download_file": "{{fileName}} をダウンロードしますか?",
            "file_infected": "ファイル {{fileName}} は感染しているためダウンロードできません。",
            "file_pending": "ファイル {{fileName}} はマルウェア スキャン中であるためダウンロードできません。",
            "preparing_download": "ダウンロードの準備をしています",
            "error_getting_download_url": "このファイルはダウンロードできません。しばらくたってから再試行するか、システム管理者に連絡してこの問題を解決してください。"
          },
          "loading": "読み込み中...",
          "add_modal": {
            "heading": "新しい {{attributeTypeName}} の追加",
            "tooltip_content": "セキュリティ上の理由から、HighBond では、.bat、.com、.exe の拡張子の添付ファイルは受け付けません。\n",
            "label": "新しい {{attributeTypeName}} のアップロード",
            "error_message_for_reaching_maximum_number": "ファイルの最大数に達しました。既存のファイルを削除して、再試行してください。",
            "error_message_for_reaching_1": "この属性タイプの 1 つのファイルのみを追加できます。既存のファイルを削除して、再試行してください。",
            "error_message_for_invalid_extension": "この添付ファイルのファイル拡張子は許可されていません。別のファイル拡張子を試してください。",
            "error_message_for_duplicated_filename": "このファイルはすでに添付ファイルとして属性にアップロードされています。",
            "error_message_for_general_error": "添付ファイルを追加できませんでした。しばらくたってから再試行するか、システム管理者に連絡してこの問題を解決してください。"
          }
        }
      },
      "global_notification": {
        "customer_object_created": {
          "success": "{{- customerObjectTypeName}}の \"{{- customerObjectName}}\" が正常に追加されました。"
        },
        "asset_updated": {
          "success": "{{- assetTypeName}}が正常に更新されました。",
          "conflict": {
            "title": "競合を保存する",
            "description": "変更が、別のユーザーによって同時に行われた変更と競合しています。\n変更を保存するには、データをコピーし、ページを更新し、変更を再度送信します。\n"
          }
        },
        "asset_deleted": {
          "success": "{{- assetTypeName}}の \"{{- assetName}}\" が正常に削除されました。",
          "error": "\"{{- assetName}}\" を削除できませんでした。"
        },
        "go_to": "\"{{- assetName}}\" に進む",
        "record_updated": {
          "success": "レコードを正常に更新しました!"
        },
        "questionnaire_sent": {
          "success": "アンケートが送信されました。"
        }
      },
      "side_navigation": {
        "objects_heading": {
          "asset_inventory": "資産",
          "default": "オブジェクト"
        },
        "records_heading": "レコード"
      },
      "error_page": {
        "page_title": {
          "asset_inventory": "{{heading}} - 資産インベントリ",
          "risk_register": "{{heading}} - リスク登録簿",
          "default": "{{heading}} - オブジェクト インベントリ"
        },
        "page_not_found": {
          "heading": "ページが見つかりませんでした",
          "body": "お探しのページが見つかりませんでした。当社の<a href='{{systemHomepage}}' rel=\"noopener noreferrer\" target=\"_blank\">システムのホームページ</a>に戻るか、<a href='{{contactSupportTeam}}' rel=\"noopener noreferrer\" target=\"_blank\">サポートチームにお問い合わせ</a>になり、さらなる支援をお受けください。"
        },
        "access_not_allowed": {
          "heading": "アクセスできません",
          "body": "当社の記録によれば、あなたにはこのページへのアクセス許可がありません。所属組織の HighBond 管理者に連絡し、アクセス権を持つ必要があるかどうかをご確認ください。または、<a href='{{systemHomepage}}' rel=\"noopener noreferrer\" target=\"_blank\">システムのホームページ</a>へと戻るか、<a href='{{contactSupportTeam}}' rel=\"noopener noreferrer\" target=\"_blank\">サポートチームにお問い合わせ</a>になり、さらなる支援をお受けください。"
        },
        "footer": "支援が必要な方は、<a href='{{visitOurSupportPage}}' rel=\"noopener noreferrer\" target=\"_blank\">当社サポートページに進み</a>、チケットを記録するか、担当者とオンラインでチャットしてください。"
      },
      "add_asset_modal": {
        "unsaved_changes_warning": "このフォームを閉じると、行った変更が失われます。",
        "add_new_item": "追加",
        "name_is_required": "名前は必須です。",
        "item_type": "項目の種類",
        "name": "名前"
      },
      "dirty_form": {
        "title": "この{{- objectTypeName}}には未保存の変更があります。",
        "missing_name_error": "\"名前\" が空白である場合、この{{- objectTypeName}}を保存できません。",
        "description": "あなたは更新中であった可能性があります。続けると、すべての未保存の変更が失われます。",
        "save_and_continue": "保存して続行",
        "discard_and_continue": "保存しないで続行",
        "cancel": "キャンセル"
      },
      "impact_reports_modal": {
        "download_report": {
          "title": "レポートをダウンロードする",
          "can_be_downloaded": "次の形式のレポートをダウンロードできます。",
          "ready_to_download": "レポートはダウンロードできます。"
        },
        "just_a_moment": {
          "title": "しばらくお待ちください。",
          "description": "あなたのレポートを準備しています。これは、レポートのサイズによっては数分かかる場合があります。レポートの準備が完了すると、[ダウンロード] ボタンが使用可能になります。"
        },
        "format_selection": {
          "label": "レポートの形式",
          "instructions": "レポートの形式を選択"
        },
        "docx_format": "Microsoft Word",
        "xlsx_format": "Microsoft Excel",
        "pptx_format": "Microsoft PowerPoint",
        "pdf_format": "PDF",
        "no_reports": {
          "title": "レポートが見つかりません。",
          "description": "現在ダウンロード可能なレポートはありません。"
        }
      },
      "errors": {
        "handlers": {
          "required_fields_missing": "{{attributeNameComponent}}が必要です。",
          "required_relationships_missing": {
            "asset_inventory": "{{attributeNameComponent}}は、1つ以上の資産または資産レコードにリンク付けされている必要があります。",
            "default": "{{attributeNameComponent}}は、1 つ以上のオブジェクトにリンク付けされている必要があります。"
          },
          "status_not_found": "デスティネーションの進捗状況、({{statusId}}) は、現在の進捗状況のワークフローにありません。",
          "questionnaire_not_found": "ハンドラーにアンケートがありません。",
          "failed_to_update_status": "ワークフローの進捗状況を更新できません。このページを更新してもう一度実行してください。問題が解決しない場合は、<a href=\"{{support_url}}\" rel=\"noopener noreferrer\" target=\"_blank\">サポート チームにお問い合わせください</a>。",
          "failed_to_send_questionnaire": "評価アンケートを送信できません。このページを更新してもう一度実行してください。問題が解決しない場合は、<a href=\"{{support_url}}\" rel=\"noopener noreferrer\" target=\"_blank\">サポート チームにお問い合わせください</a>。",
          "failed_to_publish_protobuf": "\"notify\" または \"runRobot\" アクションを実行できません。このページを更新してもう一度実行してください。問題が解決しない場合は、<a href=\"{{support_url}}\" rel=\"noopener noreferrer\" target=\"_blank\">サポート チームにお問い合わせください</a>。",
          "no_access_notification": "このアクションを実行する権限がありません。組織の HighBond 管理者に連絡して、権限を取得する必要があるかどうかを確認してください。"
        },
        "form": {
          "missing_field_value": "フィールドを空白にできません。",
          "missing_relationship_value": {
            "asset_inventory": "フィールドは、1つ以上の資産または資産レコードにリンク付けされている必要があります。",
            "default": "フィールドは 1 つ以上のオブジェクトにリンクされている必要があります。"
          },
          "invalid_field_value": "フィールドには個別の値があります。"
        },
        "configuration": {
          "section_missing_attribute_error": "見えない属性のいくつかに入力したら、続行できます。 管理者に連絡して、この{{- customerObjectTypeName}}の構成をレビューしてください。\n"
        }
      },
      "metadata": {
        "record_id": "レコード ID",
        "position": "位置",
        "priority": "優先度",
        "status": "進捗状況",
        "publish_date": "公開日",
        "publisher": "公開元",
        "assignee": "割り当てられたユーザー",
        "group": "グループ",
        "updated_at": "更新日時",
        "closed_at": "クローズ日時",
        "object_type": "オブジェクトの種類",
        "object_id": "目標 ID",
        "object_container_type": "オブジェクトコンテナの種類",
        "object_container_id": "オブジェクトコンテナ ID"
      },
      "protected": {
        "source": "ソース",
        "user_id": "ユーザー ID",
        "modified_by": "変更者",
        "status_id": "進捗状況 ID",
        "priority_id": "優先度 ID",
        "group_id": "グループ ID",
        "external_source": "外部ソース",
        "external_type": "外部の種類",
        "external_id": "外部 ID",
        "questionnaire_context": "アンケートの文脈"
      }
    }
  }
};
export default locales;
import { User } from 'types';
import { useQuery, UseQueryResult } from 'react-query';
import { useSession } from 'providers/SessionProvider';
import { getUsers } from 'api/handlers/getUsers/getUsers';

export function useUsersQuery(): UseQueryResult<User[]> {
  const session = useSession();

  return useQuery(['users', session.org_id], () => getUsers(session.org_id), {
    cacheTime: Infinity,
    staleTime: Infinity,
  });
}

import { HttpErrorResponse } from './NetworkRequest';

export enum HandlerActionType {
  TransitionStatusAction = 'TransitionStatusAction',
  NotifyAction = 'NotifyAction',
  SendQuestionnaireAction = 'SendQuestionnaireAction',
  RunRobotAction = 'RunRobotAction',
}

export enum HandlerConditionType {
  FieldsRequiredCondition = 'FieldsRequiredCondition',
}

export enum InvokeEventErrorCode {
  RequiredFieldsMissing = 'RequiredFieldsMissing',
  StatusNotFound = 'StatusNotFound',
  QuestionnaireNotFound = 'QuestionnaireNotFound',
  FailedToUpdateStatus = 'FailedToUpdateStatus',
  FailedToSendQuestionnaire = 'FailedToSendQuestionnaire',
  FailedToPublishProtobuf = 'FailedToPublishProtobuf',
}

/**
 * EventBlueprint
 */

export interface HandlerInvocationContextField {
  key: string;
  dataType: string;
  required: boolean;
  oneOf?: string[];
}

export type HandlerInvocationContextCollection = HandlerInvocationContextField[];

export interface HandlerConditionTypeInvocationContext {
  handlerConditionType: HandlerConditionType;
  context: HandlerInvocationContextCollection;
}

export interface HandlerActionTypeInvocationContext {
  handlerActionType: HandlerActionType;
  context: HandlerInvocationContextCollection;
}

export type HandlerInvocationContext = HandlerConditionTypeInvocationContext | HandlerActionTypeInvocationContext;

export interface InvocationContext {
  handlerId: string;
  context: HandlerInvocationContext[];
}

export interface EventBlueprint {
  id: string;
  adminName: string;
  displayName: string;
  description: string;
  position: string;
  type: string;
  shared: boolean;
  invocationContext: InvocationContext[];
}

export enum EventBlueprintType {
  OVERFLOW = 'overflow',
  BUTTON = 'button',
}

/**
 * Event
 */
export interface ObjectContext {
  object_id?: string;
  object_type?: string;
  object_container_id?: string;
  object_container_type?: string;
  object_ids?: string[];
  action?: string;
}

export interface TransitionStatusObjectContext extends ObjectContext {
  workflowStatusId: string;
}

export interface RunRobotObjectContext extends ObjectContext {
  object_ids: string[];
}

export interface QuestionnaireContextValue {
  display_name: string;
  value: any;
}

export interface PrerequisiteHandlerData {
  questionnaireData?: QuestionnaireData;
}

export interface SendQuestionnaireObjectContext extends ObjectContext {
  questionnaire_id: string;
  recipients: string[];
  [assetAttribute: string]: string | string[] | QuestionnaireContextValue | undefined;
}

export enum ActionContextKey {
  HandlerActionType = 'handler_action_type',
  HandlerConditionType = 'handler_condition_type',
}

export type ActionContextValue = HandlerActionType | HandlerConditionType;

export type InvokeEventHandlerContext = {
  [key in ActionContextKey]?: ActionContextValue;
} & { context: ObjectContext };

export interface QuestionnaireData {
  questionnaireId: string;
  recipients: string[];
}

export interface EventContext {
  handler_id: string;
  context: InvokeEventHandlerContext[];
}

export interface Event {
  id: string;
  invocationContext: EventContext[];
}

export interface EventError {
  code: InvokeEventErrorCode;
  source: {
    object_id: string;
    object_type: string;
    object_container_id: string;
    object_container_type: string;
  };
}

export interface AttributeError extends EventError {
  detail: {
    field_names: string[];
  };
}

export interface FieldSpecificAttributeError extends AttributeError {
  fieldName: string;
  displayName: string;
  errorNumber: number;
}

export interface RequiredFieldsMissingError extends AttributeError {
  configured_error_message: string;
}

export interface StatusNotFoundError extends EventError {
  detail: {
    status_id: string;
  };
}

export interface InvokeEventResponse<T> {
  errors: EventError[];
  customerObject: T;
}

export interface InvokeEventError {
  type: 'unknown' | 'server' | 'forbidden';
  error?: EventError | HttpErrorResponse;
}

import { Group } from 'types';
import { useQuery, UseQueryResult } from 'react-query';
import { useSession } from 'providers/SessionProvider';
import { getGroups } from 'api/handlers/getGroups/getGroups';

export function useGroupsQuery(): UseQueryResult<Group[]> {
  const session = useSession();

  return useQuery(['groups', session.org_id], () => getGroups(session.org_id), {
    cacheTime: Infinity,
    staleTime: Infinity,
  });
}

import AppConfig from 'AppConfig';
import { Group } from 'types';
import http from 'api/http';
import { handleResponse } from 'api/helpers';

export async function getGroups(orgId: string): Promise<Group[]> {
  const response = await http.get(`${AppConfig.authyApiUrl}/groups?org_id=${orgId}`);

  return await handleResponse(response, (json) => json.data as Group[]);
}

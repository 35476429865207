import { handleResponse } from 'api/helpers';
import AppConfig from 'AppConfig';
import http from 'api/http';

export default async function exportActivityLogs(orgId: string, startDate: string, endDate: string): Promise<any> {
  const response = await http.get(
    `${AppConfig.apiProxyUrl}/orgs/${orgId}/activities/export?filter[event_origin]=assets-inventory&filter[date][start]=${startDate}&filter[date][end]=${endDate}`,
  );

  return await handleResponse(response, () => {
    return null;
  });
}
